
.cal-main{
    position: absolute;

    width: 80%;
   
   top:7%;
   left:10%;
   right: 10%;
    border: 4.3px solid black;
    border-radius: 1rem;
}
.display_screen{
    @apply bg-black text-white rounded-l  text-right p-3 pt-8 rounded-b-none h-28;
}
.previous_value{
    @apply text-xs text-slate-500;
}
.design_btn{
    @apply bg-slate-600 text-white m-2 h-12 rounded-full hover:opacity-70  !important;
}
.orange_btn{
    @apply bg-orange-400 !important;
}
.grey_btn{
@apply bg-slate-300 text-black !important;
}